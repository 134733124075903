.page {
    display: flex;
    flex-direction: column;
}

.page .text a {
    color: #0366d6;
}

.page .text a:hover {
    text-decoration: underline;
}

.text, .title, .site {
    margin: 0 !important;
    margin-left: 10px !important;
}

.page-image {
    max-width: 600px;
    max-height: 400px;
    margin: 10px 0;
    text-align: center;
    overflow: hidden;
}

.page-image img {
    max-height: 100%;
    max-width: 100%;
}

.title {
    margin-top: 15px !important;
}

.site {
    margin-top: 5px !important;
}

.like.liked {
    color: rgb(17, 126, 156) !important;
}

.text {
    margin: 0 10px 5px 10px;
    word-wrap: break-word;
}
