.add-story-content {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 15px 20px 15px;
}

.add-story-content .add_story__comment_input {
  width: 100%;
  border: 0;
  margin: 10px 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
}

.add-story-content .add-story-text-fields .MuiFormControl-root,
.add-story-content .add-story-text-fields .MuiFormControl-root div,
.add-story-content .add-story-text-fields .MuiInputBase-root,
.add-story-content .add-story-text-fields .MuiInputBase-root div {
  width: 100% !important;
}

.button-container {
    width: 100%;
    text-align: right;
}

.story-preview .invalid-link {
    text-align: center;
    color: #aaa;
    padding: 20px 0;
}

.story-preview h4 {
    color: #183054;

}

.underline-indent {
    margin-top: 10px;
}
