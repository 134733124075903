.search-tabs {
    max-width: 800px;
    margin: 0 auto;
    padding: 10px 0;
}

.search-tabs > div:first-child {
    background: transparent !important;
}

.search-tabs > div:nth-child(2) div {
    background: #183054 !important;
}

.search-tab {
    color: #000 !important;
}

.search-tabs-searchfield hr{
    border-bottom: 1px solid #e0e0e0 !important;
}

.search-page {
    margin: 10px;
    align-items: stretch;
    flex: 1;
}

.search-tab-content {
    padding: 20px;
}

.followers-list-search {
    margin: 5px 24px;
}

/* Small screens */
@media all and (max-width: 420px) {
    .blacklist-container {
        padding: 0;
    }

    .user-info-avatar {
        padding-left: 0;
    }
}
