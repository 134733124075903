.activities {
    margin: 10px 0 0 10px;
    position: relative;
}

.activity {
    margin-left: 50px;
    cursor: pointer;
    font-size: 20px;
    color: #777 !important;
}

.activities svg {
    color: #777 !important;
}

.unauth-chat-bubble {
    margin-left: 90px;
}

.like {
    margin-left: 10px;
}

.activities-info {
    font-size: 13px;
    color: #aaa;
    margin-top: 5px;
}

.likes-info {
    margin-right: 25px;
}

.reading_list, .reading_list img {
    max-width: 47px;
    cursor: pointer;
}

.reading_list {
    display: inline-block;
    position: absolute;
    right: 32px;
    margin-top: 5px;
}

.likes-info-link {
  text-decoration: none !important;
  color: rgb(170, 170, 170) !important;
}

.comments-info-link {
  text-decoration: none !important;
  color: rgb(170, 170, 170) !important;
}
