.user-item {
    height: 72px;
    position: relative;
}

.story-item__unread {
    font-weight: bold;
}

.user-item__created_at_ago {
    color: gray;
    font-size: smaller;
}

.user-item-content {
    display: flex;
    height: 100%;
}

.user-item-content .container {
    display: inline-block;
    min-width: 186px;
}

.user-item-info {
    display: inline-block;
    margin-left: 10px !important;
    padding-top: 10px;
}

.user-info-name {
    font-size: 16px;
    flex-shrink: 1;
    flex-grow: 1;
    max-width: 100%;
    min-width: 0;
    padding: 20px 0 0 10px;
}

.user-item-dropdown {
    font-size: 20px;
    color: #aaa;
    display: inline-block;
    width: 48px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 0;
}

.user-info-avatar {
    padding: 20px 0 0 20px;
}

.user-info-username {
    font-size: 14px;
}

.story-item-excerpt {
    display: inline-block;
    padding-right: 20px;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    min-width: 0;
}
.story-item-info {
    padding-top: 18px;
}

.story-item-description {
    margin-top: -17px;
    color: #8e8e8e;
}

.story-item-info{
    width: 100%;
}

.story-item-image {
    width: 60px;
    padding-top: 66.6%;
    overflow: hidden;
    margin: auto;
    position: relative;
    margin-top: 20px;
}

.story-item-image img {
    position: absolute;
    margin: auto;
    height: auto;
    width: 60px;
    top: -100%;
    bottom: -100%;
}

.story-item-info {
    margin:0;
    padding:0;
    display:table;
    table-layout: fixed;
    width:100%;
    max-width:100%;
}

.story-item-info span {
    margin:0;
    display:table-cell;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
}

/* Small screens */
@media all and (max-width: 400px) {
    .search-tab-content {
        padding: 10px 5px !important;
    }
    .user-item {
        margin: 5px;
    }
}

.search_people__user-info-username {
  font-size: 14px;
  color: #8e8e8e;
}
