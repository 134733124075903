.user-page-info {
    display: flex;
    margin: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.user-page-info .avatar {
    height: 150px !important;
	width: 150px !important;
}

.user-page-info .container {
    padding: 0 10px;
}

.user-page-info .user-name {
    font-size: 20px;
}

.user-page-info .user-userName {
    font-size: 15px;
    margin-bottom: 4px;
}

.user-page-info .additional-info {
    display: flex;
    justify-content: space-around;
    margin-top: 4px;
}

.user-page-info .additional-info p {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.edit-profile {
    width: 100%;
}

.profile-block .edit-profile {
  width: 85%;
}

.additional-info .user-info-numbers {
    color: #183054;
    font-size: 25px;
}

.additional-info a {
    text-decoration: none;
}

.user-info-deactivated {
    font-size: 28px;
    text-indent: 0px;
    margin-top: 15px;
    color: #9D9D9D;
    letter-spacing: 2px;



}
