.comment {
    max-width: 580px;
    margin: 0 auto;
    margin-bottom: 5px;
}

.comment-text, .edit-comment-container{
    padding-left: 75px;
    margin-top: -30px;
    margin-right: 10px;
    text-align: justify;
}

.comment-text a {
    color: #0366d6;
}

.comment-text a:hover {
    text-decoration: underline;
}

.comment-dot {
    padding: 0 7px;
}

.comment-like {
    margin-left: 0;
}

.edit-comment-container .text-field {
    width: 100%;
}