.comment-activities {
    margin-left: 73px;
    margin-right: 10px;
    max-width: 505px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
}

.reply-comment {
    cursor: pointer;
}

.comment-activities svg {
    color: #777 !important;
}

.activities-dot {
    padding: 0 5px;
}
