body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.popover-action {
  min-width: 150px;
}

.MuiTab-wrapper {
  font-size: 14px;
}

.MuiButton-label {
  font-size: 14px;
}
