.footer-block {
    width: 100%;
    /*height: 252px;*/
    height: 100px;
    background-color: #183054;
    background-size: 100% 252px;
    background-repeat: no-repeat;
}

.footer-content {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.footer-logo-block {
    /*max-width: 855px;*/
    max-width: 180px;
    max-height: 80px;
    margin-left: 95px;
}

.footer-logo {
    max-height: 100px;
    max-width: 100%;
}

.footer-app-store, .footer-google-play {
    max-height: 60px;
    margin: 16px;
    max-width: 100%;
}

.footer-stores {
    display: flex;
    flex-direction: column;
    max-width: 285px;
    margin-right: 50px;
}

@media all and (max-width: 800px) {
    .footer-logo-block {
        margin-left: 25px;
    }
  }