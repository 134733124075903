.user_blacklisted_current_user_page_content__root {
  text-align: center;
  margin-top: 200px;
  width: 100%;
  font-size: 3em;
}

.user_blacklisted_current_user_page_content__icon {
  height: 100px !important;
  width: 100px !important;
}
