@import url(https://fonts.googleapis.com/css?family=Courgette);
.header {
  position: relative;
}

.logos-block {
  padding: 32px 80px;
  display: flex;
  justify-content: space-between;
}

.logo-block, .stores {
  padding: 0 15px;
}

.logo-block {
  display: flex;
}

.logo-statement {
  display: table;
  margin-left: 45px;
  height: 100%;
}

.logo-statement .nuuz {
  margin-top: 18px;
  margin-bottom: -20px;
  margin-right: 10px;
  height: 18px;
  aspect-ratio: initial;
}

.logo-statement .the-news-that-matters {
  margin-top: 17px;
  margin-bottom: -20px;
  margin-left: 60px;
  height: 15px;
  aspect-ratio: initial;
}

.logo-statement span {
  display: table-cell;
  vertical-align: middle;
  font-family: 'Courgette', cursive;
  color: #183054;
  font-size: 20px;
}

.logo-block-image {
  display: table;
  height: 100%;
  max-width: 205px;
}

.logo-block-image a {
  display: table-cell;
  vertical-align: middle;
}

.logo-block-image .logo {
  //width: 100%;
  margin: -12px -20px -20px -00px;
  height: 80px;
  aspect-ratio: initial;
}

.app-store {
  padding: 0 48px 0 0;
}

.app-store, .google-play {
  height: 60px;
}

.arrow {
  display: none;
  font-size: 30px;
}

.nav-block {
  background: #183054;
}

.nav-block a {
  cursor: pointer;
  display: inline-block;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: normal;
  color: #9aa8b9 !important;
  padding: 9px 8px;
  text-decoration: none !important;
  margin: 0 16px;
}

.navigation {
  list-style: none;
  margin: 0;
  padding: 8px 80px;
  display: flex;

  justify-content: flex-end;
}

.feed, .my-nuuz, .login {
  flex-grow: 0;
}

.navigation a {
  display: block;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: normal;
  color: #9aa8b9 !important;
  padding: 9px 8px;
  text-decoration: none !important;
  margin: 0 16px;
}

.navigation a:hover {
  color: #fff !important;
}

/* Navigation bar animation begin */
.nav-block-enter {
  max-height: 0;
}

.nav-block-enter.nav-block-enter-active {
  max-height: 500px;
  transition: 700ms;
}

.nav-block-leave {
  max-height: 500px;
}

.nav-block-leave.nav-block-leave-active {
  max-height: 0;
  transition: 700ms;
}
/* Navigation bar animation end */

/* Dropdown menu */
.dropdown-menu-list {
  position: absolute;
  display: none;
  z-index: 10;
  background: #fff;
  list-style: none;
  padding: 0;
  border: 1px solid #eee;
  min-width: 180px;
  margin: 0;
  box-shadow: 1px 1px 10px #eee;
}

.dropdown-menu-title {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  text-align: center;
}

.dropdown-menu-items:hover .dropdown-menu-list {
  display: block;
}

.dropdown-menu-items:hover a.dropdown-menu-title {
  background: #fff !important;
  color: #183054 !important;
}

.dropdown-menu-list li a {
  text-transform: none;
  font-size: 18px;
  padding-top: 9px;
  padding-bottom: 9px;
  font-weight: normal !important;
  color: #183054 !important;
}

.dropdown-menu-items {
  display: inline-block;
  min-width: 180px;
  line-height: 30px;
  position: relative;
}

.dropdown-menu-items.active .dropdown-menu-title {
  color: #183054 !important;
}

.dropdown-menu-item hr {
  margin: 0;
}

.dropdown-menu-items mark {
  border-radius: 30px;
  border: 2px solid #FFF;
  width: 24px;
  height: 24px;
  background-color: #FF6B6B;
  position: absolute;
  top: -10px;
  right: 6px;
  font-size: 10px;
  line-height: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #FFF;
  font-weight: 700;
  z-index: 999;
  text-align: center;
  margin: auto;
}

.dropdown-menu-item a {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding-left: 20px;
}

.fa-caret-down {
  padding-left: 10px;
}

.nav-block-content {
  display: flex;
  justify-content: flex-end;
  max-width: 1025px;
  margin: 0 auto;
  padding: 0 22px;
}

.nav-block-content > a {
  margin: 0;
  padding: 9px 20px;
  min-width: 100px;
  text-align: center;
  line-height: 30px;
}

.nav-block-content a:hover, .nav-block-content .active {
  color: #183054 !important;
  background: #fff;
}

.dropdown-menu-list a:hover, .dropdown-menu-list .active {
  background: #f5f5f5 !important;
  color: #000 !important;
}

.navbar-drawer a {
  display: block;
  height: 100%;
  width: 100%;
}

.navbar-drawer a {
  text-decoration: none;
}

.navbar-drawer {
  display: none;
}

.btn-archive {
  text-align: center;
  margin: 12px auto;
}

.btn-archive div{
  display: inline;
}

.btn-archive a {
  background-color: rgb(12, 45, 86) !important;
  width: 200px !important;
}

/* Medium screens */
@media all and (max-width: 1165px) {
  .app-store {
    padding-right: 20px;
  }

  .logo-statement {
    margin-left: 45px;
  }
}

@media all and (max-width: 1055px) {
  .stores {
    width: 190px;
  }
  .app-store, .google-play {
    padding-bottom: 5px;
  }

  .logo-statement {
    margin-left: 45px;
  }

  .logo-statement .the-news-that-matters {
    margin-left: 60px;
  }
}

@media all and (max-width: 950px) {
  .logo-statement {
    margin-left: 45px;
  }

  .logo-statement .the-news-that-matters {
    margin-left: 60px;
  }
}

@media all and (max-width: 610px) {
  .stores {
    display: none;
  }
  .logo-block {
    flex-direction: column;
  }

  .logo-block-image {
    height: 75px;
  }

  .logo-block-image a {
    vertical-align: top;
  }

  .logo-statement {
    height: 20px;
    margin: 0 0 0;
  }

  .logo-statement .nuuz {
    margin-top: 18px;
    margin-left: 45px;
  }
  
  .logo-statement .the-news-that-matters {
    margin-left: 60px;
  }
  .navigation {
    flex-direction: column;
    text-align: center;
  }
}

@media all and (max-width: 800px) {
  .navigation {
    justify-content: space-around;
  }

  .app-store, .google-play {
    display: none;
  }

  .arrow {
    display: block;
    position: absolute;
    top:40px;
    right: 50px;
    cursor: pointer;
  }

  .logos-block {
    padding-left: 10px;
  }

  .nav-block {
    display: none;
  }

  .navbar-drawer {
    display: block;
  }
}

/* Small screens */
@media all and (max-width: 570px) {
  .logo-statement .the-news-that-matters {
    margin-top: 42px;
  }
}

.footer-block {
    width: 100%;
    /*height: 252px;*/
    height: 100px;
    background-color: #183054;
    background-size: 100% 252px;
    background-repeat: no-repeat;
}

.footer-content {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.footer-logo-block {
    /*max-width: 855px;*/
    max-width: 180px;
    max-height: 80px;
    margin-left: 95px;
}

.footer-logo {
    max-height: 100px;
    max-width: 100%;
}

.footer-app-store, .footer-google-play {
    max-height: 60px;
    margin: 16px;
    max-width: 100%;
}

.footer-stores {
    display: flex;
    flex-direction: column;
    max-width: 285px;
    margin-right: 50px;
}

@media all and (max-width: 800px) {
    .footer-logo-block {
        margin-left: 25px;
    }
  }
.deactivate-page {
	height: 40vh;
}

.deactivated-container {
	max-width: 1000px;
	margin: 0 auto;
	border-radius: 2px;
	margin-top: 50px;
}

.deactivated-container h1 {
	background-color: rgb(232, 232, 232);
	padding-left: 24px;
	height: 56px;
	line-height: 56px;
	color: rgba(0, 0, 0, 0.4);
	font-family: Roboto, sans-serif;
	font-size: 20px;
}

.deactivated-container h4 {
	padding: 14px 24px 24px;
	color: #555;
}

.activate-account {
	margin-left: 10px;
	background-color: rgb(12, 45, 86) !important;
}

.activate-account button {
	color: #999 !important;
	background-color: rgb(12, 45, 86) !important;
}

.activate-account button span {
	color: #ddd !important;
}

@media screen and (max-width: 834px) {
	.deactivated-container {
		margin-left: 12px;
		margin-right: 12px;
	}
	.activate-account {
		margin: 20px 10px 0px;
	}
}

@media screen and (max-width: 482px) {
	.deactivated-container {
		margin-left: 12px;
		margin-right: 12px;
	}

	.deactivated-container h4 {
		padding: 14px 24px 14px;
		color: #555;
		line-height: 2.5;
	}

	.activate-account {
		margin: 10px 10px 0px;
	}
}

@media screen and (max-width: 357px) {
	.deactivated-container {
		margin-left: 12px;
		margin-right: 12px;
	}

	.deactivated-container h1 {
		background-color: rgb(232, 232, 232);
		padding-left: 24px;
		height: 56px;
		line-height: 56px;
		color: rgba(0, 0, 0, 0.4);
		font-family: Roboto, sans-serif;
		font-size: 18px;
	}

	.deactivated-container h4 {
		padding: 14px 24px 14px;
		color: #555;
		line-height: 1.5;
	}

	.activate-account  {
		display: block !important;
	}
}
html, body, #root {
    height: 100%;
    margin: 0;
}

.container {
    padding: 0;
    overflow-x: hidden;
}

.not-found-container {
    position: relative;
    background: #0f5e95;
    min-height: 100%;
    color: #fff;
}

.not-found-page {
    position: absolute;
    font-size: 40px;
    text-align: center;
    top: 50%;
    margin-top: -20%;
    width: 100%;
    cursor: pointer;
}

.sign-404 {
    font-family: 'Baloo 2', cursive;
    font-size: 200px;
    margin-bottom: -50px;
}

.not-found-container .content-loading {
    display: none;
}

/* Small screens */
@media all and (max-width: 600px) {
    .not-found-page {
        position: absolute;
        font-size: 30px;
        text-align: center;
        top: 50%;
        margin-top: -40%;
        width: 100%;
    }
    .sign-404 {
        font-size: 150px;
    }
}

.text-enhanced-with-links--link[disabled] {
  pointer-events: none;
  cursor: default;
  color: #7a8086 !important;
}

.disabled-link {
  pointer-events: none;
}

.block-user-label {
    color: red !important;
}

.feed_page__last_following_user_commented__container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #a29c9c40;
  padding: 3px;
}

.feed_page__last_following_user_commented__username {
  color: #0366d6;
}

.user-info {
    display: flex;
}

.add-info {
    margin-top: 4px;
}

.avatar {
    margin: 14px 15px 2px 10px;
}

.user-info .container {
    position: relative;
    min-width: 200px;
    margin: 0;
    padding-top: 12px;
}

.user-name {
    display: inline-block;
    margin: 10px 0 0 0;
    font-weight: bold;
}

.user-userCustomLocation {
    font-size: 15px;
    font-weight: bold;
    color: #aaa;
}

.user-userBio {
    font-size: 15px;
    color: #000;
}

.user-info__username-block {
  margin: 0 0 5px 0;
}

.user-userName, .created-at {
    font-size: 13px;
    color: #aaa;
}

a {
    text-decoration: none;
    color: #000;
}

.delete-comment {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #D7D7D7;
}

.delete-comment:hover {
    color: #a7a7a7;
    cursor: pointer;
}

.number-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 15px;
  border: 2px solid #f44336;
  color: #f44336;
  position: absolute;
  top: 25px;
  right: 50px;
}

.number-circle.blue {
    border: 2px solid #183054;
    color: #183054;
}

.number-circle span {
    text-align: center;
    line-height: 20px;
    display: block;
}

.user-info .story-dropdown {
    width: 30px;
    font-size: 25px;
    position: absolute;
    top: 25px;
    right: 15px;
    opacity: 0.4;
    text-align: center;
}

.user-info .story-dropdown:hover {
    opacity: 0.6;
    transition: 100ms linear;
    cursor: pointer;
}

.user-info .story-dropdown:not(:hover) {
    opacity: 0.4;
    transition: 100ms linear;
}

.user-info .comment-dropdown {
    width: 14px;
    font-size: 12px;
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0.4;
    text-align: center;
}

.user-info .comment-dropdown:hover {
    opacity: 0.6;
    transition: 100ms linear;
    cursor: pointer;
}

.user-info .comment-dropdown:not(:hover) {
    opacity: 0.4;
    transition: 100ms linear;
}

.preview {
    cursor: pointer;
}

.page {
    display: flex;
    flex-direction: column;
}

.page .text a {
    color: #0366d6;
}

.page .text a:hover {
    text-decoration: underline;
}

.text, .title, .site {
    margin: 0 !important;
    margin-left: 10px !important;
}

.page-image {
    max-width: 600px;
    max-height: 400px;
    margin: 10px 0;
    text-align: center;
    overflow: hidden;
}

.page-image img {
    max-height: 100%;
    max-width: 100%;
}

.title {
    margin-top: 15px !important;
}

.site {
    margin-top: 5px !important;
}

.like.liked {
    color: rgb(17, 126, 156) !important;
}

.text {
    margin: 0 10px 5px 10px;
    word-wrap: break-word;
}

.activities {
    margin: 10px 0 0 10px;
    position: relative;
}

.activity {
    margin-left: 50px;
    cursor: pointer;
    font-size: 20px;
    color: #777 !important;
}

.activities svg {
    color: #777 !important;
}

.unauth-chat-bubble {
    margin-left: 90px;
}

.like {
    margin-left: 10px;
}

.activities-info {
    font-size: 13px;
    color: #aaa;
    margin-top: 5px;
}

.likes-info {
    margin-right: 25px;
}

.reading_list, .reading_list img {
    max-width: 47px;
    cursor: pointer;
}

.reading_list {
    display: inline-block;
    position: absolute;
    right: 32px;
    margin-top: 5px;
}

.likes-info-link {
  text-decoration: none !important;
  color: rgb(170, 170, 170) !important;
}

.comments-info-link {
  text-decoration: none !important;
  color: rgb(170, 170, 170) !important;
}

.comment-activities {
    margin-left: 73px;
    margin-right: 10px;
    max-width: 505px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
}

.reply-comment {
    cursor: pointer;
}

.comment-activities svg {
    color: #777 !important;
}

.activities-dot {
    padding: 0 5px;
}

.add-comment-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 5px 15px 0;
}

.add-comment-container .text-field {
    width: 100% !important;
}

.text-field hr:last-child {
    border-bottom: 1px solid #6888ad !important;
}

.send-comment {
    margin: 5px 0 !important;
    width: inherit;
}

.send-comment button[disabled] {
    background-color: #e5e5e5 !important;
}

.send-comment span {
    text-transform: capitalize !important;
    padding: 8px 16px;
}

textarea::-webkit-input-placeholder {
    color: #aaaaaa;
}

textarea::-ms-input-placeholder {
    color: #aaaaaa;
}

textarea::placeholder {
    color: #aaaaaa;
}

.text-field__input {
    border: 0;
    border-bottom: 2px solid #183054;
    outline: none;
}

.text-field__highlighter {
    padding: 4px 0 7px;
    line-height: 1.2em;
}

.text-field__suggestions__list {
    max-height: 200px;
    min-width: 200px;
    overflow-y: auto;
}

.mention-focused {
    background-color: #cee4e5;
    border-top: 1px solid #eee;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 8px;
    height: 100%;
    color: #121212;
    overflow:scroll;
    overflow-x:hidden;
}

.mention-unfocused {
    border-top: 1px solid #eee;
    background-color: #fff;
    border-radius: 5px;
    cursor: pointer;
    height: 100%;
    padding: 10px 8px;
    color: #121212;
    overflow:scroll;
    overflow-x:hidden;
}

.mentions {
    background-color: #cce7ff;
    padding-left: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    text-decoration: none;
    line-height: 1.3em;
}

.mentions-avatar {
    width: 26px;
    height: 26px;
    margin-right: 10px;
    float: left;
}

.mentions-username {
    color: #383838;
}

.add-reply-block-enter {
  max-height: 0;
}

.add-reply-block-enter.add-reply-block-enter-active {
  max-height: 500px;
  transition: 700ms;
}

.add-reply-block-leave {
  max-height: 500px;
}

.add-reply-block-leave.add-reply-block-leave-active {
  max-height: 0px;
  transition: 700ms;
}

.comment-reply .add-comment-container {
    overflow: hidden;
    margin: 5px 0 5px 5px;
}

.comment-reply {
    margin: 0 10px 0 73px;
}

.comment {
    max-width: 580px;
    margin: 0 auto;
    margin-bottom: 5px;
}

.comment-text, .edit-comment-container{
    padding-left: 75px;
    margin-top: -30px;
    margin-right: 10px;
    text-align: justify;
}

.comment-text a {
    color: #0366d6;
}

.comment-text a:hover {
    text-decoration: underline;
}

.comment-dot {
    padding: 0 7px;
}

.comment-like {
    margin-left: 0;
}

.edit-comment-container .text-field {
    width: 100%;
}
.feed_page__story__paper {
    height: auto;
    max-width: 600px;
    margin: 20px;
    padding-bottom: 5px;
    display: block;
}

.feed_page__story__report_story {
    width: 100% !important;
}

.content-loading {
    display: flex;
    width: 100%;
    min-height: 70vh;
}

.small-loading {
    min-height: 40vh;
}

.list-loading {
    min-height: 15vh;
}

.content-loading div {
    margin: auto;
}

.container {
  width: 100%;
}

.page-content {
  max-width: 640px;
  min-width: 320px;
  margin: 0 auto;
  min-height: 80vh;
}

.page-content-full-height {
  display: flex;
}

/* Add animation to add delete comment */

.add-delete-story-enter {
  opacity: 0.01;
}

.add-delete-story-enter.add-delete-story-enter-active {
  opacity: 1;
  transition: 500ms ease-in;
}

.add-delete-story-leave {
  opacity: 1;
}

.add-delete-story-leave.add-delete-story-leave-active {
  opacity: 0.01;
  transition: 300ms ease-in;
}

.user-item-content .container {
  overflow-y: hidden;
}

.x-share-button {
  background-color: #000000 !important;
  color: white !important;
  border-radius: 3px;
  font-size: 14px !important;
  margin-top: 10px !important;
  margin-left: 20px !important;
  padding-bottom: 6px !important;
  padding-right: 10px !important;
  float: left;
}

.x-share-button-icon {
  margin-bottom: -11px;
  border-radius: 50px;
}

.facebook-share-button {
  background-color: #1877f2 !important;
  color: white !important;
  border-radius: 3px;
  font-size: 14px !important;
  margin-top: 10px !important;
  margin-left: 20px !important;
  padding-bottom: 6px !important;
  padding-right: 10px !important;
}

.facebook-share-button-icon {
  margin-bottom: -11px;
  padding: 4px;
}

.linkedin-share-button {
  background-color: #2d72ac !important;
  color: white !important;
  border-radius: 3px;
  font-size: 14px !important;
  margin-top: 10px !important;
  margin-left: 20px !important;
  padding-bottom: 6px !important;
  padding-right: 10px !important;
}

.linkedin-share-button-icon {
  margin-bottom: -11px;
  padding: 3px;
}

#linkedin-share-icon {
  transform-box: fill-box;
  transform-origin: center center;
  transform: scale(2.5);
}

.main-block {
	display: inline-block;
	max-width: 30%;
	float: left;
	margin: 20px 0px 0px 40px;
}

.profile-block{
	padding-top: 15px;
  width: 260px;
}

.prof-avatar {
	margin: 0px auto;
	height: 150px !important;
	width: 150px !important;
}

.name-block {
	text-align: center;
}

.user-name {
	text-align: center;
}

.additional-info {
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  margin: 0 15px;
  padding-bottom: 4px;
}

.edit-profile {
	position: relative;
	width: 85%;
}


.additional-info .user-info-numbers {
    display: block;
    text-align: center;
}


.page-button button {
	background-color: rgb(12, 45, 86) !important;
}

.page-button button span {
	color: white !important;
}

.feed-user-page-info {
	display: none;
}

.page-button .edit-profile {
	margin: 0 auto;
	display: block !important;
}


@media screen and (max-width: 997px) {
	.feed-page-content {
	    max-width: 640px;
	    min-width: 320px;
	    margin: 0 auto;
	    min-height: 80vh;
	}

	.feed-user-page-info  {
		display: block;

	}
	.main-block {
		display: none;
	}

  .edit-profile {
    width: 100%;
  }
}

.user-page-info {
    display: flex;
    margin: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.user-page-info .avatar {
    height: 150px !important;
	width: 150px !important;
}

.user-page-info .container {
    padding: 0 10px;
}

.user-page-info .user-name {
    font-size: 20px;
}

.user-page-info .user-userName {
    font-size: 15px;
    margin-bottom: 4px;
}

.user-page-info .additional-info {
    display: flex;
    justify-content: space-around;
    margin-top: 4px;
}

.user-page-info .additional-info p {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.edit-profile {
    width: 100%;
}

.profile-block .edit-profile {
  width: 85%;
}

.additional-info .user-info-numbers {
    color: #183054;
    font-size: 25px;
}

.additional-info a {
    text-decoration: none;
}

.user-info-deactivated {
    font-size: 28px;
    text-indent: 0px;
    margin-top: 15px;
    color: #9D9D9D;
    letter-spacing: 2px;



}

.story_comments_page__story_activities__activities {
    margin: 20px 0 0 10px;
    position: relative;
}

.story_comments_page__story_activities__activity {
    margin-left: 50px;
    cursor: pointer;
    font-size: 20px;
    color: #777 !important;
}

.story_comments_page__story_activities__activities_svg {
    color: #777 !important;
}

.story_comments_page__story_activities__unauth-chat-bubble {
    margin-left: 90px;
}

.story_comments_page__story_activities__like {
    margin-left: 10px;
}

.story_comments_page__story_activities__liked {
    color: rgb(17, 126, 156) !important;
}

.story_comments_page__story_activities__activities-info {
    font-size: 13px;
    color: #aaa;
    margin-top: 5px;
}

.story_comments_page__story_activities__likes-info {
  margin-right: 25px;
}

.story_comments_page__story_activities__likes-info:hover {
  cursor: pointer;
}

.story_comments_page__story_activities__reading_list, .story_comments_page__story_activities__reading_list img {
    max-width: 47px;
    cursor: pointer;
}

.story_comments_page__story_activities__reading_list {
    display: inline-block;
    position: absolute;
    right: 32px;
}

.story_comments_page__story_activities__comments-info:hover {
  cursor: pointer;
}

.story_comments_page__story_activities__comments-info-link {
    text-decoration: none !important;
    color: rgb(170, 170, 170) !important;
}

.story_comments_page__story__paper {
    height: auto;
    max-width: 600px;
    margin: 20px;
    padding-bottom: 5px;
    display: block;
}

.story_comments_page__story__report-story {
    width: 100% !important;
}

.story_comments_page__story_likes_list__root_container {
  height: auto;
  max-width: 600px;
  margin: 20px;
  display: block;
  padding: 10px 0;
}

.story_comments_page__story_likes_list__story_item_container {
  display: flex;
}

.story_comments_page__story_likes_list__avatar_container {
  padding: 5px;
  padding-left: 14px;
}

.story_comments_page__story_likes_list__username {
  font-weight: bold;
}

.story_comments_page__story_likes_list__username:hover {
  text-decoration: none;
}

.story_comments_page__story_likes_list__created_info {
  font-weight: bold;
}

.story_comments_page__story_likes_list__right_container {
  padding-top: 13px;
  padding-left: 7px;
}

.story_comments_page__story_likes_list__no_likes {
  margin: 10px;
  text-align: center;
  color: #aaa;
}

.container {
    min-width: 280px;
}

.comments-container {
    height: auto;
    max-width: 600px;
    margin: 20px;
    display: block;
    padding: 10px 0;
}

.no-comments {
    margin: 10px;
    text-align: center;
    color: #aaa;
}

.comment-pagination {
    width: 100%;
    display: flex;
    justify-content: center;
}

/* Add animation to add delete comment */

.add-delete-comment-enter {
    opacity: 0.01;
}

.add-delete-comment-enter.add-delete-comment-enter-active {
    opacity: 1;
    transition: 300ms ease-in;
}

.add-delete-comment-leave {
    opacity: 1;
}

.add-delete-comment-leave.add-delete-comment-leave-active {
    opacity: 0.01;
    transition: 300ms ease-out;
}

.login-link {
    color: #15c !important;
}

.unauth-add-comment {
    font-size: 15px;
    padding: 20px 20px 10px;
    text-align: center;
}

.paper {
    height: auto;
    max-width: 600px;
    margin: 20px;
    padding-bottom: 5px;
    display: block;
}

.report-story {
    width: 100% !important;
}

.no-stories {
    font-size: 16px;
    color: #aaa;
    text-align: center;
    padding: 20px 0px;
    line-height: 250px;
}

.report-post-group {
    color: black;
}

.nuuz-archive-content__title {
  padding: 30px 21px 13px 39px;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: normal;
}

.user-page {
    margin: 10px;
    align-items: stretch;
    flex: 1 1;
    padding-top: 10px;
}

.profile-edit-content{
    max-width: 600px;
    margin: 0 auto;
}

.user-page-info-profile-edit .user-page-info {
    border-bottom: none;
}

.user-page-info .container {
    min-width: 280px;
}

.search-page-searchfield {
    margin-left: 20px
}

.search-page-searchfield hr:nth-child(2) {
    border-bottom: 1px solid #183054 !important;
}

@media all and (max-width: 600px) {
    .user-page {
        padding-top: 0;
    }
    .user-page-info {
        flex-direction: column;
        text-align: center;
        margin-top: 0;
    }

    .user-page-info .container .user-name {
        margin-top: 0;
    }
}

.user_blacklisted_current_user_page_content__root {
  text-align: center;
  margin-top: 200px;
  width: 100%;
  font-size: 3em;
}

.user_blacklisted_current_user_page_content__icon {
  height: 100px !important;
  width: 100px !important;
}

.login-form {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 10px 50px;
    margin: auto;
}

.login-form div {
    width: 100% !important;
    min-width: 300px ;
}

.submit-button {
    width: 100%;
    justify-content: flex-end;
    margin-top: 0px;
    position: relative;
}

a.forgot-password {
    color: #4078c0;
    top: 0;
    right: 0;
    float: right;
}

.login-form label {
    font-weight: normal;
    color: #6888ad !important
}

/* Small screens */
@media all and (max-width: 420px) {
    .login-form {
        max-width: 280px;
        padding: 0 10px 5px 10px;
    }

    .login-form div {
        min-width: 260px;
    }
}

.login-form-container {
    max-width: 500px;
    margin: auto;
}

.login-form-container__reset_password {
  width: 80%;
}

.login-page-content {
    display: flex;
    padding: 0 10px;

    max-width: 1000px;
    min-width: 320px;
    margin: 0 auto;
    min-height: 80vh;
}

.signup-form {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 10px 50px;
    margin: auto;
}

.signup-form label {
    font-weight: normal;
    color: #6888ad !important;
}

.signup-submit-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 15px 0 10px;
}

.signup-submit-button .signup-form-back {
    max-width: 85px;
    width: 100%;
    min-height: 50px;
}

.signup-submit-button .send-comment {
    max-width: 85px;
    width: 100%;
    max-height: 50px;
    margin-left: 10px;
}

.signup-form-back span {
    text-transform: capitalize !important;
}

.reset-password-submit {
    margin-top: -5px !important;
    float: right;
}

/* Small screens */
@media all and (max-width: 420px) {
    .signup-email-form {
        max-width: 280px;
        padding: 0 10px 5px 10px;
    }

    .signup-email-form div {
        min-width: 260px !important;
    }

    .signup-form {
        max-width: 280px;
        padding: 0 10px 5px 10px;
    }
}

.signup-email-form {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 10px 50px;
    margin: auto;
}

.signup-email-form div {
    width: 100% !important;
    min-width: 300px ;
}

.signup-email-form label {
    color: #6888ad !important;
    font-weight: normal;
}

.signup-email-form .signup-email-error {
    color: #ff0000;
}

.signup-email-form h4 {
    width: 100%;
    text-align: left !important;
    color: #aaa;
}

.submit-button {
    margin-top: 15px;
}

.facebook-button {
    max-width: 400px;
    cursor: pointer;
    line-height: 36px;
    background: #6888ad !important;
    text-align: center;
    border-radius: 2px;
    transition: 150ms linear;
    margin: 5px 0;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    display: block;
}

.facebook-button .facebook-icon {
    margin-right: 5px;
    font-size: 18px;
}


.facebook-button:hover {
    background: rgba(104, 136, 173, 0.6) !important;
    transition: 150ms linear;
}

.divider {
    border-bottom: 1px solid #E1E0CC;
    position: relative;
    margin: 15px 0;
    color: #aaa;
}

.divider span {
    text-align: center;
    position: absolute;
    font-size: 14px;
    width: 40px;
    right: 44%;
    top: -10px;
    background: #fff;
}

.signup-form-container {
    max-width: 500px;
    margin: auto;
}

.signup-page-content {
    display: flex;
    padding: 0 10px;

    max-width: 1000px;
    min-width: 320px;
    margin: 0 auto;
    min-height: 80vh;
}

.image-picker-container {
    position: relative;
    max-width: 200px;
}

.avatar-dropzone {
    max-width: 200px;
    padding-top: 100%;
    overflow: hidden;
    margin: auto;
    position: relative;
}

.image-picker {
    max-width: 200px;
    max-height: 200px;
    cursor: pointer;
    pointer-events: none;
}

.fa-camera {
    font-size: 30px;
    display: block;
    position: absolute;
    top: 40px;
    left: 35px;
    opacity: 0.9;
    color: #e0e0e0;
}

.select-avatar-text {
    display: block;
    position: absolute;
    bottom: 24px;
    width: 100%;
    text-align: center;
    font-size: 17px;
    opacity: 0.1;
    color: #d0d0d0;
}

/* Mouse hover image picker zone animatio start */
.image-picker:hover .fa-camera, .image-picker:hover .select-avatar-text {
    font-size: 40px;
    opacity: 0.8;
    transition: 100ms linear;
}

.image-picker:hover .select-avatar-text {
    font-size: 18px;
}

.image-picker:not(:hover) .fa-camera, .image-picker:not(:hover) .select-avatar-text {
    font-size: 30px;
    opacity: 0.9;
    transition: 100ms linear;
}

.image-picker:not(:hover) .select-avatar-text {
    font-size: 17px;
}
/* Mouse hover image picker zone animatio end */

.cancel-selection {
    position: absolute;
    top: 0;
    right: -20px;
    color: #D7D7D7;
}

.cancel-selection:hover {
    color: #a7a7a7;
    cursor: pointer;
}

.profile-edit-container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 30px 10px 10px;
}

.profile-edit-container .profile-edit-image-container {
    position: absolute;
    top: 70px;
    right: 10px;
    height: 100%;
    width: 100%;
    max-height: 200px;
    max-width: 200px;
}

.profile-edit-textfields {
    display: inline-flex;
    min-width: 250px;
    flex-direction: column;
}

.profile-edit-birthdate div div {
    color: #6888ad !important;
}

.edit-profile-parent-submit-container {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
}

.edit-profile-submit-deactivate-button {
    justify-content: flex-start;
}
.edit-profile-parent-deactivate-button {
    height: 34px;
}

.edit-profile-submit-container {
    box-sizing: border-box;
    display: inline-flex;
    flex: 0 1 auto;
    flex-direction: row;
    justify-content: flex-end;
}

.edit-profile-submit-container.pull-right {
    position: absolute;
    right: 0;
    bottom: 5px;
}

.edit-profile-submit {
  text-align: right !important;
  max-width: 125px;
  width: 100%;
}

.edit-profile-cancel {
    margin-right: 10px !important;
}

.edit-profile-cancel button {
    background-color: #fff !important;
}

.profile-edit-container h4 {
    margin-top: 20px;
    color: #183054;
}

.toggle-field {
    width: 256px !important;
    margin: 5px 0;
}

@media all and (max-width: 600px) {
    .profile-edit-container {
        position: inherit;
        display: block;
    }
    .profile-edit-container .profile-edit-image-container {
        position: inherit;
        max-width: 600px;
    }

    .profile-edit-textfields {
        align-items: center;
    }

    .image-picker-container {
        margin: 0 auto;
    }

    .edit-profile-submit-container {
        width: 256px;
        justify-content: center;
    }

    .edit-profile-submit-container .edit-profile-cancel, .edit-profile-submit-container .edit-profile-submit {
        min-width: 123px !important;
    }
}

.edit-profile__change-password-button {
  max-width: 193px;
  width: 100%;
  margin-bottom: 10px;
}

.edit-profile__change-password-button button {
  background-color: #6888ad !important;
}

.edit-profile__blocked-users-button {
    max-width: 193px;
    width: 100%;
    margin-bottom: 10px !important;
    background-color: #838383 !important;
  }

#select-gender-label {
    font-size: 10.5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

#select-privacy-label {
    font-size: 10.5px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.profile-change-password-container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0px 10px 10px;
}

.add-story-content {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 15px 20px 15px;
}

.add-story-content .add_story__comment_input {
  width: 100%;
  border: 0;
  margin: 10px 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
}

.add-story-content .add-story-text-fields .MuiFormControl-root,
.add-story-content .add-story-text-fields .MuiFormControl-root div,
.add-story-content .add-story-text-fields .MuiInputBase-root,
.add-story-content .add-story-text-fields .MuiInputBase-root div {
  width: 100% !important;
}

.button-container {
    width: 100%;
    text-align: right;
}

.story-preview .invalid-link {
    text-align: center;
    color: #aaa;
    padding: 20px 0;
}

.story-preview h4 {
    color: #183054;

}

.underline-indent {
    margin-top: 10px;
}

.user-item {
    height: 72px;
    position: relative;
}

.story-item__unread {
    font-weight: bold;
}

.user-item__created_at_ago {
    color: gray;
    font-size: smaller;
}

.user-item-content {
    display: flex;
    height: 100%;
}

.user-item-content .container {
    display: inline-block;
    min-width: 186px;
}

.user-item-info {
    display: inline-block;
    margin-left: 10px !important;
    padding-top: 10px;
}

.user-info-name {
    font-size: 16px;
    flex-shrink: 1;
    flex-grow: 1;
    max-width: 100%;
    min-width: 0;
    padding: 20px 0 0 10px;
}

.user-item-dropdown {
    font-size: 20px;
    color: #aaa;
    display: inline-block;
    width: 48px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 0;
}

.user-info-avatar {
    padding: 20px 0 0 20px;
}

.user-info-username {
    font-size: 14px;
}

.story-item-excerpt {
    display: inline-block;
    padding-right: 20px;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    min-width: 0;
}
.story-item-info {
    padding-top: 18px;
}

.story-item-description {
    margin-top: -17px;
    color: #8e8e8e;
}

.story-item-info{
    width: 100%;
}

.story-item-image {
    width: 60px;
    padding-top: 66.6%;
    overflow: hidden;
    margin: auto;
    position: relative;
    margin-top: 20px;
}

.story-item-image img {
    position: absolute;
    margin: auto;
    height: auto;
    width: 60px;
    top: -100%;
    bottom: -100%;
}

.story-item-info {
    margin:0;
    padding:0;
    display:table;
    table-layout: fixed;
    width:100%;
    max-width:100%;
}

.story-item-info span {
    margin:0;
    display:table-cell;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
}

/* Small screens */
@media all and (max-width: 400px) {
    .search-tab-content {
        padding: 10px 5px !important;
    }
    .user-item {
        margin: 5px;
    }
}

.search_people__user-info-username {
  font-size: 14px;
  color: #8e8e8e;
}

.blacklist-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 15px;
}

.blacklist-list-item:hover {
    background: transparent !important;
    cursor: default !important;
}

.follows-list-top {
    max-width: 770px;
    padding: 15px 15px;
    margin: 0 auto;
}

.search-tabs {
    max-width: 800px;
    margin: 0 auto;
    padding: 10px 0;
}

.search-tabs > div:first-child {
    background: transparent !important;
}

.search-tabs > div:nth-child(2) div {
    background: #183054 !important;
}

.search-tab {
    color: #000 !important;
}

.search-tabs-searchfield hr{
    border-bottom: 1px solid #e0e0e0 !important;
}

.search-page {
    margin: 10px;
    align-items: stretch;
    flex: 1 1;
}

.search-tab-content {
    padding: 20px;
}

.followers-list-search {
    margin: 5px 24px;
}

/* Small screens */
@media all and (max-width: 420px) {
    .blacklist-container {
        padding: 0;
    }

    .user-info-avatar {
        padding-left: 0;
    }
}

.add-reading-list-content {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 15px 20px 15px;
}

.add-reading-list-content .add-reading-list-text-fields div {
    width: 100% !important;
}

.button-container {
    width: 100%;
    text-align: right;
}

.reading-list-preview .invalid-link {
    text-align: center;
    color: #aaa;
    padding: 20px 0;
}

.reading-list-preview h4 {
    color: #183054;

}
.sortable-list {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    margin: 0 50px;
}

.no-publications {
    width: 100%;
}

.publications-image {
    height: 150px;
    width: 150px;
}

.publications-image div {
    position: relative;
}

.publications-image-container {
    height: 150px;
    width: 150px;
    margin: 5px;
    position: relative;
}

.publications-image-container:hover .publication-title {
    background: rgba(0, 0, 0, 0.6);
    transition: 200ms linear;
}

.publications-image-container:hover .publications-image {
    background: rgba(100, 100, 100, 0.05) !important;
}

.publications-image-container img {
    width: 90%;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    margin: 5px;
}

.publications-page-content {
    width: 100%;
    margin: 10px;
    padding: 10px 50px 10px 25px;
}

.delete-publication {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    padding: 5px;
    color: rgb(215, 215, 215);
}

.delete-publication:hover {
    color: rgb(167, 167, 167);
    cursor: pointer;
}

.publication-title {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    font-size: 18px;
    line-height: 40px;
    color: #fff;
    padding-left: 10px;
    background: rgba(0, 0, 0, 0.4);
}

.publications-search {
    margin: 0 0 20px 54px;
}

.subheader-20 {
    font-size: 20px !important;
}


@media all and (max-width: 600px) {
    .publications-page-content {
        padding-right: 25px;
    }

    .publications-page-content .subheader-20 {
        padding-left: 0 !important;
    }

    .publications-search {
        margin-left: 0;
    }

    .publications-page-content .sortable-list {
        margin-left: 0;
    }
}

@media all and (max-width: 550px) {
    .sortable-list {
        max-width: 320px;
        margin: 0 auto !important;
    }
}

@media all and (max-width: 390px) {
    .sortable-list {
        max-width: 160px;
        margin: 0 auto !important;
    }
}
.activity-history-page {
	margin: 10px;
}

.alerts-page {
	margin: 10px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.popover-action {
  min-width: 150px;
}

.MuiTab-wrapper {
  font-size: 14px;
}

.MuiButton-label {
  font-size: 14px;
}

