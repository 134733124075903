.profile-edit-container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 30px 10px 10px;
}

.profile-edit-container .profile-edit-image-container {
    position: absolute;
    top: 70px;
    right: 10px;
    height: 100%;
    width: 100%;
    max-height: 200px;
    max-width: 200px;
}

.profile-edit-textfields {
    display: inline-flex;
    min-width: 250px;
    flex-direction: column;
}

.profile-edit-birthdate div div {
    color: #6888ad !important;
}

.edit-profile-parent-submit-container {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
}

.edit-profile-submit-deactivate-button {
    justify-content: flex-start;
}
.edit-profile-parent-deactivate-button {
    height: 34px;
}

.edit-profile-submit-container {
    box-sizing: border-box;
    display: inline-flex;
    flex: 0 1 auto;
    flex-direction: row;
    justify-content: flex-end;
}

.edit-profile-submit-container.pull-right {
    position: absolute;
    right: 0;
    bottom: 5px;
}

.edit-profile-submit {
  text-align: right !important;
  max-width: 125px;
  width: 100%;
}

.edit-profile-cancel {
    margin-right: 10px !important;
}

.edit-profile-cancel button {
    background-color: #fff !important;
}

.profile-edit-container h4 {
    margin-top: 20px;
    color: #183054;
}

.toggle-field {
    width: 256px !important;
    margin: 5px 0;
}

@media all and (max-width: 600px) {
    .profile-edit-container {
        position: inherit;
        display: block;
    }
    .profile-edit-container .profile-edit-image-container {
        position: inherit;
        max-width: 600px;
    }

    .profile-edit-textfields {
        align-items: center;
    }

    .image-picker-container {
        margin: 0 auto;
    }

    .edit-profile-submit-container {
        width: 256px;
        justify-content: center;
    }

    .edit-profile-submit-container .edit-profile-cancel, .edit-profile-submit-container .edit-profile-submit {
        min-width: 123px !important;
    }
}

.edit-profile__change-password-button {
  max-width: 193px;
  width: 100%;
  margin-bottom: 10px;
}

.edit-profile__change-password-button button {
  background-color: #6888ad !important;
}

.edit-profile__blocked-users-button {
    max-width: 193px;
    width: 100%;
    margin-bottom: 10px !important;
    background-color: #838383 !important;
  }

#select-gender-label {
    font-size: 10.5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

#select-privacy-label {
    font-size: 10.5px;
    margin-top: 5px;
    margin-bottom: 5px;
}