.signup-email-form {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 10px 50px;
    margin: auto;
}

.signup-email-form div {
    width: 100% !important;
    min-width: 300px ;
}

.signup-email-form label {
    color: #6888ad !important;
    font-weight: normal;
}

.signup-email-form .signup-email-error {
    color: #ff0000;
}

.signup-email-form h4 {
    width: 100%;
    text-align: left !important;
    color: #aaa;
}

.submit-button {
    margin-top: 15px;
}

.facebook-button {
    max-width: 400px;
    cursor: pointer;
    line-height: 36px;
    background: #6888ad !important;
    text-align: center;
    border-radius: 2px;
    transition: 150ms linear;
    margin: 5px 0;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    display: block;
}

.facebook-button .facebook-icon {
    margin-right: 5px;
    font-size: 18px;
}


.facebook-button:hover {
    background: rgba(104, 136, 173, 0.6) !important;
    transition: 150ms linear;
}

.divider {
    border-bottom: 1px solid #E1E0CC;
    position: relative;
    margin: 15px 0;
    color: #aaa;
}

.divider span {
    text-align: center;
    position: absolute;
    font-size: 14px;
    width: 40px;
    right: 44%;
    top: -10px;
    background: #fff;
}
