.deactivate-page {
	height: 40vh;
}

.deactivated-container {
	max-width: 1000px;
	margin: 0 auto;
	border-radius: 2px;
	margin-top: 50px;
}

.deactivated-container h1 {
	background-color: rgb(232, 232, 232);
	padding-left: 24px;
	height: 56px;
	line-height: 56px;
	color: rgba(0, 0, 0, 0.4);
	font-family: Roboto, sans-serif;
	font-size: 20px;
}

.deactivated-container h4 {
	padding: 14px 24px 24px;
	color: #555;
}

.activate-account {
	margin-left: 10px;
	background-color: rgb(12, 45, 86) !important;
}

.activate-account button {
	color: #999 !important;
	background-color: rgb(12, 45, 86) !important;
}

.activate-account button span {
	color: #ddd !important;
}

@media screen and (max-width: 834px) {
	.deactivated-container {
		margin-left: 12px;
		margin-right: 12px;
	}
	.activate-account {
		margin: 20px 10px 0px;
	}
}

@media screen and (max-width: 482px) {
	.deactivated-container {
		margin-left: 12px;
		margin-right: 12px;
	}

	.deactivated-container h4 {
		padding: 14px 24px 14px;
		color: #555;
		line-height: 2.5;
	}

	.activate-account {
		margin: 10px 10px 0px;
	}
}

@media screen and (max-width: 357px) {
	.deactivated-container {
		margin-left: 12px;
		margin-right: 12px;
	}

	.deactivated-container h1 {
		background-color: rgb(232, 232, 232);
		padding-left: 24px;
		height: 56px;
		line-height: 56px;
		color: rgba(0, 0, 0, 0.4);
		font-family: Roboto, sans-serif;
		font-size: 18px;
	}

	.deactivated-container h4 {
		padding: 14px 24px 14px;
		color: #555;
		line-height: 1.5;
	}

	.activate-account  {
		display: block !important;
	}
}