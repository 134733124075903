.add-reply-block-enter {
  max-height: 0;
}

.add-reply-block-enter.add-reply-block-enter-active {
  max-height: 500px;
  transition: 700ms;
}

.add-reply-block-leave {
  max-height: 500px;
}

.add-reply-block-leave.add-reply-block-leave-active {
  max-height: 0px;
  transition: 700ms;
}

.comment-reply .add-comment-container {
    overflow: hidden;
    margin: 5px 0 5px 5px;
}

.comment-reply {
    margin: 0 10px 0 73px;
}
