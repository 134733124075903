.login-form {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 10px 50px;
    margin: auto;
}

.login-form div {
    width: 100% !important;
    min-width: 300px ;
}

.submit-button {
    width: 100%;
    justify-content: flex-end;
    margin-top: 0px;
    position: relative;
}

a.forgot-password {
    color: #4078c0;
    top: 0;
    right: 0;
    float: right;
}

.login-form label {
    font-weight: normal;
    color: #6888ad !important
}

/* Small screens */
@media all and (max-width: 420px) {
    .login-form {
        max-width: 280px;
        padding: 0 10px 5px 10px;
    }

    .login-form div {
        min-width: 260px;
    }
}
