.story_comments_page__story_activities__activities {
    margin: 20px 0 0 10px;
    position: relative;
}

.story_comments_page__story_activities__activity {
    margin-left: 50px;
    cursor: pointer;
    font-size: 20px;
    color: #777 !important;
}

.story_comments_page__story_activities__activities_svg {
    color: #777 !important;
}

.story_comments_page__story_activities__unauth-chat-bubble {
    margin-left: 90px;
}

.story_comments_page__story_activities__like {
    margin-left: 10px;
}

.story_comments_page__story_activities__liked {
    color: rgb(17, 126, 156) !important;
}

.story_comments_page__story_activities__activities-info {
    font-size: 13px;
    color: #aaa;
    margin-top: 5px;
}

.story_comments_page__story_activities__likes-info {
  margin-right: 25px;
}

.story_comments_page__story_activities__likes-info:hover {
  cursor: pointer;
}

.story_comments_page__story_activities__reading_list, .story_comments_page__story_activities__reading_list img {
    max-width: 47px;
    cursor: pointer;
}

.story_comments_page__story_activities__reading_list {
    display: inline-block;
    position: absolute;
    right: 32px;
}

.story_comments_page__story_activities__comments-info:hover {
  cursor: pointer;
}

.story_comments_page__story_activities__comments-info-link {
    text-decoration: none !important;
    color: rgb(170, 170, 170) !important;
}
