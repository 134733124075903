.blacklist-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 15px;
}

.blacklist-list-item:hover {
    background: transparent !important;
    cursor: default !important;
}
