.image-picker-container {
    position: relative;
    max-width: 200px;
}

.avatar-dropzone {
    max-width: 200px;
    padding-top: 100%;
    overflow: hidden;
    margin: auto;
    position: relative;
}

.image-picker {
    max-width: 200px;
    max-height: 200px;
    cursor: pointer;
    pointer-events: none;
}

.fa-camera {
    font-size: 30px;
    display: block;
    position: absolute;
    top: 40px;
    left: 35px;
    opacity: 0.9;
    color: #e0e0e0;
}

.select-avatar-text {
    display: block;
    position: absolute;
    bottom: 24px;
    width: 100%;
    text-align: center;
    font-size: 17px;
    opacity: 0.1;
    color: #d0d0d0;
}

/* Mouse hover image picker zone animatio start */
.image-picker:hover .fa-camera, .image-picker:hover .select-avatar-text {
    font-size: 40px;
    opacity: 0.8;
    transition: 100ms linear;
}

.image-picker:hover .select-avatar-text {
    font-size: 18px;
}

.image-picker:not(:hover) .fa-camera, .image-picker:not(:hover) .select-avatar-text {
    font-size: 30px;
    opacity: 0.9;
    transition: 100ms linear;
}

.image-picker:not(:hover) .select-avatar-text {
    font-size: 17px;
}
/* Mouse hover image picker zone animatio end */

.cancel-selection {
    position: absolute;
    top: 0;
    right: -20px;
    color: #D7D7D7;
}

.cancel-selection:hover {
    color: #a7a7a7;
    cursor: pointer;
}
