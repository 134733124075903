.nuuz-archive-content__title {
  padding: 30px 21px 13px 39px;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: normal;
}

.user-page {
    margin: 10px;
    align-items: stretch;
    flex: 1;
    padding-top: 10px;
}

.profile-edit-content{
    max-width: 600px;
    margin: 0 auto;
}

.user-page-info-profile-edit .user-page-info {
    border-bottom: none;
}

.user-page-info .container {
    min-width: 280px;
}

.search-page-searchfield {
    margin-left: 20px
}

.search-page-searchfield hr:nth-child(2) {
    border-bottom: 1px solid #183054 !important;
}

@media all and (max-width: 600px) {
    .user-page {
        padding-top: 0;
    }
    .user-page-info {
        flex-direction: column;
        text-align: center;
        margin-top: 0;
    }

    .user-page-info .container .user-name {
        margin-top: 0;
    }
}
