.sortable-list {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    margin: 0 50px;
}

.no-publications {
    width: 100%;
}

.publications-image {
    height: 150px;
    width: 150px;
}

.publications-image div {
    position: relative;
}

.publications-image-container {
    height: 150px;
    width: 150px;
    margin: 5px;
    position: relative;
}

.publications-image-container:hover .publication-title {
    background: rgba(0, 0, 0, 0.6);
    transition: 200ms linear;
}

.publications-image-container:hover .publications-image {
    background: rgba(100, 100, 100, 0.05) !important;
}

.publications-image-container img {
    width: 90%;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    margin: 5px;
}

.publications-page-content {
    width: 100%;
    margin: 10px;
    padding: 10px 50px 10px 25px;
}

.delete-publication {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    padding: 5px;
    color: rgb(215, 215, 215);
}

.delete-publication:hover {
    color: rgb(167, 167, 167);
    cursor: pointer;
}

.publication-title {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    font-size: 18px;
    line-height: 40px;
    color: #fff;
    padding-left: 10px;
    background: rgba(0, 0, 0, 0.4);
}

.publications-search {
    margin: 0 0 20px 54px;
}

.subheader-20 {
    font-size: 20px !important;
}


@media all and (max-width: 600px) {
    .publications-page-content {
        padding-right: 25px;
    }

    .publications-page-content .subheader-20 {
        padding-left: 0 !important;
    }

    .publications-search {
        margin-left: 0;
    }

    .publications-page-content .sortable-list {
        margin-left: 0;
    }
}

@media all and (max-width: 550px) {
    .sortable-list {
        max-width: 320px;
        margin: 0 auto !important;
    }
}

@media all and (max-width: 390px) {
    .sortable-list {
        max-width: 160px;
        margin: 0 auto !important;
    }
}