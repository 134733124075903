.content-loading {
    display: flex;
    width: 100%;
    min-height: 70vh;
}

.small-loading {
    min-height: 40vh;
}

.list-loading {
    min-height: 15vh;
}

.content-loading div {
    margin: auto;
}
