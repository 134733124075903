.user-info {
    display: flex;
}

.add-info {
    margin-top: 4px;
}

.avatar {
    margin: 14px 15px 2px 10px;
}

.user-info .container {
    position: relative;
    min-width: 200px;
    margin: 0;
    padding-top: 12px;
}

.user-name {
    display: inline-block;
    margin: 10px 0 0 0;
    font-weight: bold;
}

.user-userCustomLocation {
    font-size: 15px;
    font-weight: bold;
    color: #aaa;
}

.user-userBio {
    font-size: 15px;
    color: #000;
}

.user-info__username-block {
  margin: 0 0 5px 0;
}

.user-userName, .created-at {
    font-size: 13px;
    color: #aaa;
}

a {
    text-decoration: none;
    color: #000;
}

.delete-comment {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #D7D7D7;
}

.delete-comment:hover {
    color: #a7a7a7;
    cursor: pointer;
}

.number-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 15px;
  border: 2px solid #f44336;
  color: #f44336;
  position: absolute;
  top: 25px;
  right: 50px;
}

.number-circle.blue {
    border: 2px solid #183054;
    color: #183054;
}

.number-circle span {
    text-align: center;
    line-height: 20px;
    display: block;
}

.user-info .story-dropdown {
    width: 30px;
    font-size: 25px;
    position: absolute;
    top: 25px;
    right: 15px;
    opacity: 0.4;
    text-align: center;
}

.user-info .story-dropdown:hover {
    opacity: 0.6;
    transition: 100ms linear;
    cursor: pointer;
}

.user-info .story-dropdown:not(:hover) {
    opacity: 0.4;
    transition: 100ms linear;
}

.user-info .comment-dropdown {
    width: 14px;
    font-size: 12px;
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0.4;
    text-align: center;
}

.user-info .comment-dropdown:hover {
    opacity: 0.6;
    transition: 100ms linear;
    cursor: pointer;
}

.user-info .comment-dropdown:not(:hover) {
    opacity: 0.4;
    transition: 100ms linear;
}

.preview {
    cursor: pointer;
}
