.feed_page__last_following_user_commented__container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #a29c9c40;
  padding: 3px;
}

.feed_page__last_following_user_commented__username {
  color: #0366d6;
}
