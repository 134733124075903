@import url('https://fonts.googleapis.com/css?family=Courgette');

.header {
  position: relative;
}

.logos-block {
  padding: 32px 80px;
  display: flex;
  justify-content: space-between;
}

.logo-block, .stores {
  padding: 0 15px;
}

.logo-block {
  display: flex;
}

.logo-statement {
  display: table;
  margin-left: 45px;
  height: 100%;
}

.logo-statement .nuuz {
  margin-top: 18px;
  margin-bottom: -20px;
  margin-right: 10px;
  height: 18px;
  aspect-ratio: initial;
}

.logo-statement .the-news-that-matters {
  margin-top: 17px;
  margin-bottom: -20px;
  margin-left: 60px;
  height: 15px;
  aspect-ratio: initial;
}

.logo-statement span {
  display: table-cell;
  vertical-align: middle;
  font-family: 'Courgette', cursive;
  color: #183054;
  font-size: 20px;
}

.logo-block-image {
  display: table;
  height: 100%;
  max-width: 205px;
}

.logo-block-image a {
  display: table-cell;
  vertical-align: middle;
}

.logo-block-image .logo {
  //width: 100%;
  margin: -12px -20px -20px -00px;
  height: 80px;
  aspect-ratio: initial;
}

.app-store {
  padding: 0 48px 0 0;
}

.app-store, .google-play {
  height: 60px;
}

.arrow {
  display: none;
  font-size: 30px;
}

.nav-block {
  background: #183054;
}

.nav-block a {
  cursor: pointer;
  display: inline-block;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: normal;
  color: #9aa8b9 !important;
  padding: 9px 8px;
  text-decoration: none !important;
  margin: 0 16px;
}

.navigation {
  list-style: none;
  margin: 0;
  padding: 8px 80px;

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  justify-content: flex-end;
  -webkit-flex-flow: row wrap;
}

.feed, .my-nuuz, .login {
  flex-grow: 0;
}

.navigation a {
  display: block;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: normal;
  color: #9aa8b9 !important;
  padding: 9px 8px;
  text-decoration: none !important;
  margin: 0 16px;
}

.navigation a:hover {
  color: #fff !important;
}

/* Navigation bar animation begin */
.nav-block-enter {
  max-height: 0;
}

.nav-block-enter.nav-block-enter-active {
  max-height: 500px;
  transition: 700ms;
}

.nav-block-leave {
  max-height: 500px;
}

.nav-block-leave.nav-block-leave-active {
  max-height: 0;
  transition: 700ms;
}
/* Navigation bar animation end */

/* Dropdown menu */
.dropdown-menu-list {
  position: absolute;
  display: none;
  z-index: 10;
  background: #fff;
  list-style: none;
  padding: 0;
  border: 1px solid #eee;
  min-width: 180px;
  margin: 0;
  box-shadow: 1px 1px 10px #eee;
}

.dropdown-menu-title {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  text-align: center;
}

.dropdown-menu-items:hover .dropdown-menu-list {
  display: block;
}

.dropdown-menu-items:hover a.dropdown-menu-title {
  background: #fff !important;
  color: #183054 !important;
}

.dropdown-menu-list li a {
  text-transform: none;
  font-size: 18px;
  padding-top: 9px;
  padding-bottom: 9px;
  font-weight: normal !important;
  color: #183054 !important;
}

.dropdown-menu-items {
  display: inline-block;
  min-width: 180px;
  line-height: 30px;
  position: relative;
}

.dropdown-menu-items.active .dropdown-menu-title {
  color: #183054 !important;
}

.dropdown-menu-item hr {
  margin: 0;
}

.dropdown-menu-items mark {
  border-radius: 30px;
  border: 2px solid #FFF;
  width: 24px;
  height: 24px;
  background-color: #FF6B6B;
  position: absolute;
  top: -10px;
  right: 6px;
  font-size: 10px;
  line-height: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #FFF;
  font-weight: 700;
  z-index: 999;
  text-align: center;
  margin: auto;
}

.dropdown-menu-item a {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding-left: 20px;
}

.fa-caret-down {
  padding-left: 10px;
}

.nav-block-content {
  display: flex;
  justify-content: flex-end;
  max-width: 1025px;
  margin: 0 auto;
  padding: 0 22px;
}

.nav-block-content > a {
  margin: 0;
  padding: 9px 20px;
  min-width: 100px;
  text-align: center;
  line-height: 30px;
}

.nav-block-content a:hover, .nav-block-content .active {
  color: #183054 !important;
  background: #fff;
}

.dropdown-menu-list a:hover, .dropdown-menu-list .active {
  background: #f5f5f5 !important;
  color: #000 !important;
}

.navbar-drawer a {
  display: block;
  height: 100%;
  width: 100%;
}

.navbar-drawer a {
  text-decoration: none;
}

.navbar-drawer {
  display: none;
}

.btn-archive {
  text-align: center;
  margin: 12px auto;
}

.btn-archive div{
  display: inline;
}

.btn-archive a {
  background-color: rgb(12, 45, 86) !important;
  width: 200px !important;
}

/* Medium screens */
@media all and (max-width: 1165px) {
  .app-store {
    padding-right: 20px;
  }

  .logo-statement {
    margin-left: 45px;
  }
}

@media all and (max-width: 1055px) {
  .stores {
    width: 190px;
  }
  .app-store, .google-play {
    padding-bottom: 5px;
  }

  .logo-statement {
    margin-left: 45px;
  }

  .logo-statement .the-news-that-matters {
    margin-left: 60px;
  }
}

@media all and (max-width: 950px) {
  .logo-statement {
    margin-left: 45px;
  }

  .logo-statement .the-news-that-matters {
    margin-left: 60px;
  }
}

@media all and (max-width: 610px) {
  .stores {
    display: none;
  }
  .logo-block {
    flex-direction: column;
  }

  .logo-block-image {
    height: 75px;
  }

  .logo-block-image a {
    vertical-align: top;
  }

  .logo-statement {
    height: 20px;
    margin: 0 0 0;
  }

  .logo-statement .nuuz {
    margin-top: 18px;
    margin-left: 45px;
  }
  
  .logo-statement .the-news-that-matters {
    margin-left: 60px;
  }
  .navigation {
    flex-direction: column;
    text-align: center;
  }
}

@media all and (max-width: 800px) {
  .navigation {
    justify-content: space-around;
  }

  .app-store, .google-play {
    display: none;
  }

  .arrow {
    display: block;
    position: absolute;
    top:40px;
    right: 50px;
    cursor: pointer;
  }

  .logos-block {
    padding-left: 10px;
  }

  .nav-block {
    display: none;
  }

  .navbar-drawer {
    display: block;
  }
}

/* Small screens */
@media all and (max-width: 570px) {
  .logo-statement .the-news-that-matters {
    margin-top: 42px;
  }
}
