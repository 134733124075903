.signup-form {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 10px 50px;
    margin: auto;
}

.signup-form label {
    font-weight: normal;
    color: #6888ad !important;
}

.signup-submit-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 15px 0 10px;
}

.signup-submit-button .signup-form-back {
    max-width: 85px;
    width: 100%;
    min-height: 50px;
}

.signup-submit-button .send-comment {
    max-width: 85px;
    width: 100%;
    max-height: 50px;
    margin-left: 10px;
}

.signup-form-back span {
    text-transform: capitalize !important;
}

.reset-password-submit {
    margin-top: -5px !important;
    float: right;
}

/* Small screens */
@media all and (max-width: 420px) {
    .signup-email-form {
        max-width: 280px;
        padding: 0 10px 5px 10px;
    }

    .signup-email-form div {
        min-width: 260px !important;
    }

    .signup-form {
        max-width: 280px;
        padding: 0 10px 5px 10px;
    }
}
