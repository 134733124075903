html, body, #root {
    height: 100%;
    margin: 0;
}

.container {
    padding: 0;
    overflow-x: hidden;
}

.not-found-container {
    position: relative;
    background: #0f5e95;
    min-height: 100%;
    color: #fff;
}

.not-found-page {
    position: absolute;
    font-size: 40px;
    text-align: center;
    top: 50%;
    margin-top: -20%;
    width: 100%;
    cursor: pointer;
}

.sign-404 {
    font-family: 'Baloo 2', cursive;
    font-size: 200px;
    margin-bottom: -50px;
}

.not-found-container .content-loading {
    display: none;
}

/* Small screens */
@media all and (max-width: 600px) {
    .not-found-page {
        position: absolute;
        font-size: 30px;
        text-align: center;
        top: 50%;
        margin-top: -40%;
        width: 100%;
    }
    .sign-404 {
        font-size: 150px;
    }
}

.text-enhanced-with-links--link[disabled] {
  pointer-events: none;
  cursor: default;
  color: #7a8086 !important;
}

.disabled-link {
  pointer-events: none;
}

.block-user-label {
    color: red !important;
}
