.story_comments_page__story_likes_list__root_container {
  height: auto;
  max-width: 600px;
  margin: 20px;
  display: block;
  padding: 10px 0;
}

.story_comments_page__story_likes_list__story_item_container {
  display: flex;
}

.story_comments_page__story_likes_list__avatar_container {
  padding: 5px;
  padding-left: 14px;
}

.story_comments_page__story_likes_list__username {
  font-weight: bold;
}

.story_comments_page__story_likes_list__username:hover {
  text-decoration: none;
}

.story_comments_page__story_likes_list__created_info {
  font-weight: bold;
}

.story_comments_page__story_likes_list__right_container {
  padding-top: 13px;
  padding-left: 7px;
}

.story_comments_page__story_likes_list__no_likes {
  margin: 10px;
  text-align: center;
  color: #aaa;
}
