.add-reading-list-content {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 15px 20px 15px;
}

.add-reading-list-content .add-reading-list-text-fields div {
    width: 100% !important;
}

.button-container {
    width: 100%;
    text-align: right;
}

.reading-list-preview .invalid-link {
    text-align: center;
    color: #aaa;
    padding: 20px 0;
}

.reading-list-preview h4 {
    color: #183054;

}