.add-comment-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 5px 15px 0;
}

.add-comment-container .text-field {
    width: 100% !important;
}

.text-field hr:last-child {
    border-bottom: 1px solid #6888ad !important;
}

.send-comment {
    margin: 5px 0 !important;
    width: inherit;
}

.send-comment button[disabled] {
    background-color: #e5e5e5 !important;
}

.send-comment span {
    text-transform: capitalize !important;
    padding: 8px 16px;
}

textarea::placeholder {
    color: #aaaaaa;
}

.text-field__input {
    border: 0;
    border-bottom: 2px solid #183054;
    outline: none;
}

.text-field__highlighter {
    padding: 4px 0 7px;
    line-height: 1.2em;
}

.text-field__suggestions__list {
    max-height: 200px;
    min-width: 200px;
    overflow-y: auto;
}

.mention-focused {
    background-color: #cee4e5;
    border-top: 1px solid #eee;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 8px;
    height: 100%;
    color: #121212;
    overflow:scroll;
    overflow-x:hidden;
}

.mention-unfocused {
    border-top: 1px solid #eee;
    background-color: #fff;
    border-radius: 5px;
    cursor: pointer;
    height: 100%;
    padding: 10px 8px;
    color: #121212;
    overflow:scroll;
    overflow-x:hidden;
}

.mentions {
    background-color: #cce7ff;
    padding-left: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    text-decoration: none;
    line-height: 1.3em;
}

.mentions-avatar {
    width: 26px;
    height: 26px;
    margin-right: 10px;
    float: left;
}

.mentions-username {
    color: #383838;
}
