.paper {
    height: auto;
    max-width: 600px;
    margin: 20px;
    padding-bottom: 5px;
    display: block;
}

.report-story {
    width: 100% !important;
}

.no-stories {
    font-size: 16px;
    color: #aaa;
    text-align: center;
    padding: 20px 0px;
    line-height: 250px;
}

.report-post-group {
    color: black;
}
