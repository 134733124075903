.container {
  width: 100%;
}

.page-content {
  max-width: 640px;
  min-width: 320px;
  margin: 0 auto;
  min-height: 80vh;
}

.page-content-full-height {
  display: flex;
}

/* Add animation to add delete comment */

.add-delete-story-enter {
  opacity: 0.01;
}

.add-delete-story-enter.add-delete-story-enter-active {
  opacity: 1;
  transition: 500ms ease-in;
}

.add-delete-story-leave {
  opacity: 1;
}

.add-delete-story-leave.add-delete-story-leave-active {
  opacity: 0.01;
  transition: 300ms ease-in;
}

.user-item-content .container {
  overflow-y: hidden;
}

.x-share-button {
  background-color: #000000 !important;
  color: white !important;
  border-radius: 3px;
  font-size: 14px !important;
  margin-top: 10px !important;
  margin-left: 20px !important;
  padding-bottom: 6px !important;
  padding-right: 10px !important;
  float: left;
}

.x-share-button-icon {
  margin-bottom: -11px;
  border-radius: 50px;
}

.facebook-share-button {
  background-color: #1877f2 !important;
  color: white !important;
  border-radius: 3px;
  font-size: 14px !important;
  margin-top: 10px !important;
  margin-left: 20px !important;
  padding-bottom: 6px !important;
  padding-right: 10px !important;
}

.facebook-share-button-icon {
  margin-bottom: -11px;
  padding: 4px;
}

.linkedin-share-button {
  background-color: #2d72ac !important;
  color: white !important;
  border-radius: 3px;
  font-size: 14px !important;
  margin-top: 10px !important;
  margin-left: 20px !important;
  padding-bottom: 6px !important;
  padding-right: 10px !important;
}

.linkedin-share-button-icon {
  margin-bottom: -11px;
  padding: 3px;
}

#linkedin-share-icon {
  transform-box: fill-box;
  transform-origin: center center;
  transform: scale(2.5);
}
