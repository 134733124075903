.main-block {
	display: inline-block;
	max-width: 30%;
	float: left;
	margin: 20px 0px 0px 40px;
}

.profile-block{
	padding-top: 15px;
  width: 260px;
}

.prof-avatar {
	margin: 0px auto;
	height: 150px !important;
	width: 150px !important;
}

.name-block {
	text-align: center;
}

.user-name {
	text-align: center;
}

.additional-info {
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  margin: 0 15px;
  padding-bottom: 4px;
}

.edit-profile {
	position: relative;
	width: 85%;
}


.additional-info .user-info-numbers {
    display: block;
    text-align: center;
}


.page-button button {
	background-color: rgb(12, 45, 86) !important;
}

.page-button button span {
	color: white !important;
}

.feed-user-page-info {
	display: none;
}

.page-button .edit-profile {
	margin: 0 auto;
	display: block !important;
}


@media screen and (max-width: 997px) {
	.feed-page-content {
	    max-width: 640px;
	    min-width: 320px;
	    margin: 0 auto;
	    min-height: 80vh;
	}

	.feed-user-page-info  {
		display: block;

	}
	.main-block {
		display: none;
	}

  .edit-profile {
    width: 100%;
  }
}
