.container {
    min-width: 280px;
}

.comments-container {
    height: auto;
    max-width: 600px;
    margin: 20px;
    display: block;
    padding: 10px 0;
}

.no-comments {
    margin: 10px;
    text-align: center;
    color: #aaa;
}

.comment-pagination {
    width: 100%;
    display: flex;
    justify-content: center;
}

/* Add animation to add delete comment */

.add-delete-comment-enter {
    opacity: 0.01;
}

.add-delete-comment-enter.add-delete-comment-enter-active {
    opacity: 1;
    transition: 300ms ease-in;
}

.add-delete-comment-leave {
    opacity: 1;
}

.add-delete-comment-leave.add-delete-comment-leave-active {
    opacity: 0.01;
    transition: 300ms ease-out;
}

.login-link {
    color: #15c !important;
}

.unauth-add-comment {
    font-size: 15px;
    padding: 20px 20px 10px;
    text-align: center;
}
