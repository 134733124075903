.signup-form-container {
    max-width: 500px;
    margin: auto;
}

.signup-page-content {
    display: flex;
    padding: 0 10px;

    max-width: 1000px;
    min-width: 320px;
    margin: 0 auto;
    min-height: 80vh;
}
